import React, { createContext, useState } from "react";
import { useSelector } from "react-redux";

const initialState = {
  showSidebar: false,
  paylinkType: "Personal",
  fullname: "",
  linkImage: null,
  linkTitle: "",
  linkDescription: "",
  showContactInformation: true,
  minAmount: "",
  maxAmount: "",
  desiredAmount: false,
  paymentLimits: false,
  defaultAmount: "",
  successfulLink: "",
  unsuccessfulLink: "",
  successfulMessage:
    "Thank you! Your payment was successful. We will contact you soon.",
  unsuccessfulMessage:
    "We're sorry! Your payment was unsuccessful. Please try again.",
  confirmationPage: false,
  customConfirmationPage: false,
  determination: false,
  day: 1,
  count: 1,

  createDiscount: false,
  discountCode: "",
  percent: "",
  constant: "",
  products: [],
  errors: {},

  showPaymentPreview: false,
  showReceiptPreview: false,

  businessDescription: "",
  openConfirm: false,
};

export const PaylinkContext = createContext();

export const PaylinkProvider = ({ children }) => {
  const [state, setInitState] = useState(initialState);

  const setState = (state) => {
    setInitState((prevState) => ({ ...prevState, ...state }));
  };

  return (
    <PaylinkContext.Provider value={{ state, setState }}>
      {children}
    </PaylinkContext.Provider>
  );
};
