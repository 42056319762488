import {
  Avatar,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import {
  arcticMistColor,
  forestShadowColor,
  mintCreamColor,
  primaryColor,
  silverMistColor,
  silverSageColor,
  silverSandColor,
  slateGrayColor,
  whiteColor,
} from "../../../assets/colors";
import Button from "../../../components/Button";
import { useContext, useEffect, useState } from "react";
import styled from "@emotion/styled";
import Title from "../../../components/Title";
import { CheckIcon, ChevronDownIcon, ImageIcon } from "../../../assets/icons";
import PaylinkConfig from "./Config";
import PaymentPagePreview from "./PaymentPreview";
import ReceiptPreview from "./ReceiptPreview";
import {
  appBarHeight,
  dmSans,
  drawerWidth,
  fullHeight,
} from "../../../utils/constants";
import {
  addBalanceSeparator,
  removeBalanceSeparator,
} from "../../../utils/helpers";
import { URL_REGEX } from "../../../utils/regex";
import {
  createPersonalPaylinkRequestAction,
  createProductPaylinkRequestAction,
} from "../../../redux/actions/paylinkAction";
import { useDispatch, useSelector } from "react-redux";
import {
  CREATE_PERSONAL_PAYLINK_STARTED,
  CREATE_PRODUCT_PAYLINK_STARTED,
} from "../../../redux/actionTypes";
import { useNavigate } from "react-router-dom";
import ProductConfig from "./ProductConfig";
import ProductPreview from "./ProductPreview";
import Ellipse90 from "./Ellipse90.svg";
import moment from "moment-jalaali";
import { PaylinkContext } from "../../../context/PyalinkContext";
import CustomTextInput from "../../../components/CustomTextInput";

const Paylink = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { rType } = useSelector((state) => state.paylink);
  const { user } = useSelector((state) => state.auth);

  const [selectedTab, setSelectedTab] = useState("PaymentPage");
  const { state, setState } = useContext(PaylinkContext);

  const {
    fullname,
    linkTitle,
    customConfirmationPage,
    desiredAmount,
    confirmationPage,
    count,
    linkDescription,
    showContactInformation,
    day,
    defaultAmount,
    determination,
    linkImage,
    paymentLimits,
    maxAmount,
    minAmount,
    successfulMessage,
    successfulLink,
    unsuccessfulLink,
    unsuccessfulMessage,

    discountCode,
    percent,
    constant,
    createDiscount,
    products,
    businessDescription,
  } = state;

  let allProductAmount = state.products
    .map((p) => Number(p.amount))
    .reduce((sum, current) => sum + current, 0)
    .toFixed(2);

  useEffect(() => {
    setState({ showSidebar: true });
  }, []);

  const renderPreview = () => {
    switch (selectedTab) {
      case "PaymentPage":
        switch (state.paylinkType) {
          case "Personal":
            return <PaymentPagePreview />;
          case "Product":
            return <ProductPreview />;
        }
      case "ReceiptPage":
        return <ReceiptPreview />;
    }
  };

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleCreatePersoanlPaylink = (e) => {
    e.preventDefault();
    const errorObj = {};

    if (linkTitle.trim() === "")
      errorObj.linkTitle = "لطفا عنوان لینک را وارد کنید.";
    if (fullname.trim() === "")
      errorObj.fullname = "لطفا برای اطلاعات پی لینک خود یک نام انگلیسی بنویسید.";
    if (customConfirmationPage && !URL_REGEX.test(successfulLink))
      errorObj.successfulLink = "فرمت لینک صحیح نیست.";
    if (customConfirmationPage && !URL_REGEX.test(unsuccessfulLink))
      errorObj.unsuccessfulLink = "فرمت لینک صحیح نیست.";
    if (
      desiredAmount &&
      (removeBalanceSeparator(defaultAmount) < 5 ||
        removeBalanceSeparator(defaultAmount) > 2500)
    )
      errorObj.defaultAmount = "لطفا مبلغی بین ۵ یورو تا ۲,۵۰۰ یورو وارد کنید.";
    if (paymentLimits && (minAmount < 5 || maxAmount > 2500))
      errorObj.amount = "لطفا مبلغی بین ۵ یورو تا ۲,۵۰۰ یورو وارد کنید.";
    if (determination && day === "" && count === "")
      errorObj.determination = "لطفا میزان پرداخت را مشخص کنید.";

    setState({ errors: errorObj });

    if (Object.keys(errorObj).length > 0) return;

    setState({ openConfirm: true });
  };

  const handleCreateProductPaylink = (e) => {
    e.preventDefault();
    const errorObj = {};

    let totalAmount = products
      .map((p) => Number(p.amount))
      .reduce((sum, current) => sum + current, 0);

    if (linkTitle.trim() === "")
      errorObj.linkTitle = "لطفا عنوان لینک را وارد کنید.";
    if (fullname.trim() === "")
      errorObj.fullname = "لطفا برای اطلاعات پی لینک خود یک نام بنویسید.";
    if (totalAmount < 5 || totalAmount > 2500)
      errorObj.products = "مجموع قیمت محصولات باید بین ۵ تا ۲۵۰۰ یورو باشد.";
    if (products.length < 1)
      errorObj.products = "برای ساخت پی‌لینک باید حداقل یک محصول انتخاب شود.";
    if (createDiscount && discountCode === "")
      errorObj.discountCode = "لطفا شناسه کد را وارد کنید.";
    if (createDiscount && percent === "" && constant === "")
      errorObj.percent = "لطفا درصد یا عدد ثابت را وارد کنید.";
    if (determination && (day === "" || count === ""))
      errorObj.determination = "لطفا میزان پرداخت را مشخص کنید.";

    setState({ errors: errorObj });

    if (Object.keys(errorObj).length > 0) return;

    setState({ openConfirm: true });
  };

  const renderbusinessDescription = (
    <Dialog
      open={state.openConfirm}
      PaperProps={{
        sx: {
          borderRadius: 5,
          boxShadow: "0px 0px 77px 20px #5F5F5F08",
        },
      }}
      maxWidth="sm"
      BackdropProps={{
        sx: {
          background: "rgba(0, 0, 0, 0.5)",
          backdropFilter: "blur(9px)",
          left: { lg: drawerWidth },
          top: appBarHeight,
        },
      }}
      sx={{
        left: { lg: drawerWidth },
        top: appBarHeight,
        // display: { xs: "none", lg: "block" },
      }}
      onClose={() =>
        setState({
          openConfirm: false,
        })
      }
      scroll="body"
    >
      <DialogContent sx={{ pb: 0, px: 2.2 }}>
        <Typography sx={{ fontSize: 14, lineHeight: 2 }}>
          برای فعال‌سازی پی‌لینک، کارشناسان ما نیاز به بررسی دقیق کسب‌وکار شما
          دارند. لطفا در این بخش توضیحات کاملی از کسب‌وکارتان ارائه دهید تا
          فرآیند بررسی و فعال‌سازی به سرعت و با دقت انجام شود.
        </Typography>
        <CustomTextInput
          // label="دلیل  پی لینک"
          multiline
          row={3}
          value={businessDescription}
          onChange={(e) => setState({ businessDescription: e.target.value })}
          placeholder="... متن توضیح را وارد کنید"
          error={state.errors.businessDescription}
          helperText={state.errors.businessDescription}
          persian
        />
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: "space-between",
          px: "24px",
          pb: "38px",
          pt: "17px",
        }}
      >
        <Button
          color="silverSage"
          variant="outlined"
          text="بازگشت"
          sx={{ width: 131, borderRadius: 100, color: slateGrayColor }}
          onClick={() => setState({ openConfirm: false })}
        />
        <Button
          text="ثبت پی لینک"
          sx={{ borderRadius: 100, width: 253 }}
          loading={
            rType === CREATE_PERSONAL_PAYLINK_STARTED ||
            rType === CREATE_PRODUCT_PAYLINK_STARTED
          }
          onClick={
            state.paylinkType === "Personal"
              ? () => {
                  const errorObj = {};

                  if (businessDescription.trim() === "")
                    errorObj.businessDescription =
                      "لطفا توضیحات خود را ارائه دهید.";

                  setState({ errors: errorObj });

                  if (Object.keys(errorObj).length > 0) return;

                  let formData = new FormData();
                  formData.append("title", linkTitle);
                  formData.append("image", linkImage);
                  formData.append("description", linkDescription);
                  formData.append("name_en", fullname);
                  formData.append("contact_show", showContactInformation);
                  if (desiredAmount)
                    formData.append("default_amount", removeBalanceSeparator(defaultAmount));
                  if (paymentLimits) {
                    formData.append("min_amount", minAmount);
                    formData.append("max_amount", maxAmount);
                  }
                  if (determination) {
                    day && formData.append("expire_days", day);
                    count && formData.append("expire_count", count);
                  }
                  if (confirmationPage) {
                    formData.append("successful_message", successfulMessage);
                    formData.append(
                      "unsuccessful_message",
                      unsuccessfulMessage
                    );
                  }
                  if (customConfirmationPage) {
                    formData.append("successful_link", successfulLink);
                    formData.append("unsuccessful_link", unsuccessfulLink);
                  }
                  formData.append("businessDescription", businessDescription);

                  dispatch(
                    createPersonalPaylinkRequestAction({
                      data: formData,
                      navigate,
                    })
                  );
                }
              : () => {
                  const errorObj = {};

                  if (businessDescription.trim() === "")
                    errorObj.businessDescription =
                      "لطفا توضیحات خود را ارائه دهید.";

                  setState({ errors: errorObj });

                  if (Object.keys(errorObj).length > 0) return;

                  let data = {
                    name_en: fullname,
                    title: linkTitle,
                    ids: products.map((item) => item.id),
                    discount_code: discountCode,
                    contact_show: showContactInformation,
                  };
                  if (percent) data.discount_percent = percent;
                  if (constant) data.discount_amount = constant;
                  if (determination) {
                    data.expire_days = day;
                    data.expire_count = count;
                  }
                  if (confirmationPage) {
                    data.successful_message = successfulMessage;
                    data.unsuccessful_message = unsuccessfulMessage;
                  }
                  if (customConfirmationPage) {
                    data.successful_link = successfulLink;
                    data.unsuccessful_link = unsuccessfulLink;
                  }
                  data.businessDescription = businessDescription;

                  dispatch(
                    createProductPaylinkRequestAction({ data, navigate })
                  );
                }
          }
        />
      </DialogActions>
    </Dialog>
  );

  const renderConfig = () => {
    return (
      <Box
        sx={{
          backgroundColor: whiteColor,
          width: { lg: 380 },
          left: state.showSidebar ? 0 : -380,
          position: { lg: "relative" },
          transition: "all 1s",
          flex: { xs: 1, lg: "none" },
        }}
      >
        <Box
          sx={{
            overflowY: "auto",
            height: { lg: `calc(${fullHeight} - ${appBarHeight + 75}px)` },
            p: 2,
          }}
        >
          <Title text="انتخاب نوع پی لینک" containerProps={{ sx: { mb: 3 } }} />
          {selectedTab === "PaymentPage" && (
            <Box
              sx={{
                backgroundColor: arcticMistColor,
                px: "16px",
                borderRadius: "6px",
                mb: 2,
                // position: "sticky",
                top: 0,
                left: 0,
                right: 0,
                // bottom: 0,
                zIndex: 99,
                pb: state.showPaymentPreview ? "16px" : 0,
                display: { lg: "none" },
              }}
            >
              <Box
                sx={{
                  height: 56,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
                onClick={() =>
                  setState({ showPaymentPreview: !state.showPaymentPreview })
                }
              >
                <Typography>صفحه پرداخت پیش نمایش</Typography>
                <ChevronDownIcon width={18} />
              </Box>
              <Box
                sx={{
                  maxHeight: state.showPaymentPreview ? fullHeight : 0,
                  visibility: state.showPaymentPreview ? "visible" : "hidden",
                  opacity: state.showPaymentPreview ? 1 : 0,
                  transition: "all 0.5s ease",
                }}
              >
                <Box
                  sx={{
                    backgroundColor: whiteColor,
                    px: "8px",
                    py: "16px",
                    textAlign: "right",
                  }}
                >
                  {state.paylinkType === "Personal" ? (
                    <Box
                      sx={{
                        backgroundColor: arcticMistColor,
                        borderRadius: 1,
                        p: 1,
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "row-reverse",
                        mb: 2,
                      }}
                    >
                      {state.linkImage ? (
                        <Avatar
                          sx={{ width: 40, height: 40, ml: 1 }}
                          src={URL.createObjectURL(state.linkImage)}
                        />
                      ) : (
                        <Box
                          sx={{
                            width: 40,
                            height: 40,
                            backgroundColor: silverSageColor,
                            borderRadius: 100,
                            ml: 1,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <ImageIcon />
                        </Box>
                      )}
                      <Box>
                        <Typography
                          sx={{
                            fontSize: 10,
                            fontWeight: 700,
                            lineHeight: 2,
                          }}
                        >
                          {state.linkTitle
                            ? state.linkTitle
                            : " Your custom name"}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: 8,
                          }}
                        >
                          {state.linkDescription
                            ? state.linkDescription
                            : "Your description will be shown here!"}
                        </Typography>
                      </Box>
                    </Box>
                  ) : (
                    <>
                      <Typography sx={{ fontWeight: 700, mb: "20px" }}>
                        Products
                      </Typography>
                      {state.products.map((p) => (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            mb: 1.5,
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: 10,
                              fontWeight: 600,
                            }}
                          >
                            {p.amount} {p.symbol}
                          </Typography>
                          <Box sx={{ ml: "auto", mr: 1 }}>
                            <Typography sx={{ fontSize: 10, fontWeight: 600 }}>
                              {p.title}
                            </Typography>
                            <Typography
                              sx={{ fontSize: 8, color: slateGrayColor }}
                            >
                              {p.detail}
                            </Typography>
                          </Box>
                          <Avatar
                            src={p.image}
                            variant="rounded"
                            sx={{ width: 40, h4ight: 40 }}
                          />
                        </Box>
                      ))}
                      <Box
                        sx={{
                          borderTop: `1px solid ${silverMistColor}`,
                          my: 2,
                          pt: 0.15,
                          textAlign: "left",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: 10,
                            fontWeight: 700,

                            display: "inline-block",
                          }}
                        >
                          {state.products[0]?.symbol}
                          {allProductAmount}
                        </Typography>{" "}
                        <Typography
                          sx={{ display: "inline-block", fontSize: 10 }}
                        >
                          :Total
                        </Typography>{" "}
                      </Box>
                    </>
                  )}

                  {state.paylinkType === "Personal" && (
                    <>
                      <Typography sx={{ fontSize: 10, fontWeight: 500, mb: 1 }}>
                        Amount
                      </Typography>
                      <Box
                        sx={{
                          backgroundColor: state.desiredAmount
                            ? arcticMistColor
                            : "none",
                          p: 1,
                          borderRadius: 1,
                          mb: 1,
                          border: state.focusAmount
                            ? `1px solid ${primaryColor}`
                            : `1px solid ${silverSageColor}`,
                          height: 42,
                        }}
                      >
                        {state.desiredAmount ? (
                          <Typography
                            sx={{
                              fontWeight: 500,
                            }}
                          >
                            {state.defaultAmount}
                          </Typography>
                        ) : (
                          <Typography sx={{ fontSize: 6 }}>
                            Enter amount
                          </Typography>
                        )}
                      </Box>
                    </>
                  )}

                  <Typography sx={{ fontSize: 8, fontWeight: 500 }}>
                    First Name
                  </Typography>
                  <Box
                    sx={{
                      border: `1px solid ${silverSageColor}`,
                      p: 0.8,
                      borderRadius: 1,
                      mb: 2,
                    }}
                  >
                    <Typography sx={{ fontSize: 6 }}>
                      Enter your first name
                    </Typography>
                  </Box>

                  <Typography sx={{ fontSize: 8, fontWeight: 500 }}>
                    Last Name
                  </Typography>
                  <Box
                    sx={{
                      border: `1px solid ${silverSageColor}`,
                      p: 0.8,
                      borderRadius: 1,
                      mb: 2,
                    }}
                  >
                    <Typography sx={{ fontSize: 6 }}>
                      Enter your last name
                    </Typography>
                  </Box>

                  <Typography sx={{ fontSize: 8, fontWeight: 500 }}>
                    Email
                  </Typography>
                  <Box
                    sx={{
                      border: `1px solid ${silverSageColor}`,
                      p: 0.8,
                      borderRadius: 1,
                      mb: 2,
                    }}
                  >
                    <Typography sx={{ fontSize: 6 }}>
                      Enter your email
                    </Typography>
                  </Box>

                  <Typography sx={{ fontSize: 8, fontWeight: 500 }}>
                    Mobile
                  </Typography>
                  <Box
                    sx={{
                      border: `1px solid ${silverSageColor}`,
                      p: 0.8,
                      borderRadius: 1,
                      mb: 2,
                    }}
                  >
                    <Typography sx={{ fontSize: 6 }}>
                      Enter your mobile
                    </Typography>
                  </Box>

                  <Typography sx={{ fontSize: 8, fontWeight: 500 }}>
                    Country
                  </Typography>
                  <Box
                    sx={{
                      border: `1px solid ${silverSageColor}`,
                      p: 0.8,
                      borderRadius: 1,
                      mb: 2,
                    }}
                  >
                    <Typography sx={{ fontSize: 6 }}>
                      Enter your country
                    </Typography>
                  </Box>

                  <Typography sx={{ fontSize: 8, fontWeight: 500 }}>
                    Address
                  </Typography>
                  <Box
                    sx={{
                      border: `1px solid ${silverSageColor}`,
                      p: 0.8,
                      borderRadius: 1,
                    }}
                  >
                    <Typography sx={{ fontSize: 6 }}>
                      Enter your address
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}

          {selectedTab === "ReceiptPage" && (
            <Box
              sx={{
                backgroundColor: arcticMistColor,
                px: "16px",
                borderRadius: "6px",
                mb: 2,
                // position: "sticky",
                top: 0,
                left: 0,
                right: 0,
                // bottom: 0,
                zIndex: 99,
                pb: state.showReceiptPreview ? "16px" : 0,
                display: { lg: "none" },
              }}
            >
              <Box
                sx={{
                  height: 56,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
                onClick={() =>
                  setState({ showReceiptPreview: !state.showReceiptPreview })
                }
              >
                <Typography>پیش نمایش صفحه فاکتور</Typography>
                <ChevronDownIcon width={18} />
              </Box>
              <Box
                sx={{
                  visibility: state.showReceiptPreview ? "visible" : "hidden",
                  opacity: state.showReceiptPreview ? 1 : 0,
                  transition: "all .5s",
                  height: state.showReceiptPreview ? 384 : 0,
                }}
              >
                <Box
                  sx={{
                    border: `1px solid ${mintCreamColor}`,
                    backgroundColor: whiteColor,
                    width: 294,
                    height: 384,
                    borderRadius: 2.5,
                    overflow: "hidden",
                    display: "flex",
                    flexDirection: "column",
                    mx: "auto",
                  }}
                >
                  <Ellipse90 />
                  <Box
                    sx={{ mx: "auto", pt: 4, flex: 1, position: "relative" }}
                  >
                    <Box
                      sx={{
                        width: 52,
                        height: 52,
                        backgroundColor: primaryColor,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        border: `4px solid ${whiteColor}`,
                        borderRadius: 100,
                        position: "absolute",
                        top: -26,
                        left: "50%",
                        transform: "translateX(-50%)",
                      }}
                    >
                      <CheckIcon
                        width={36.4}
                        height={36.4}
                        color={whiteColor}
                      />{" "}
                    </Box>
                    <Typography
                      sx={{
                        fontSize: 12,
                        color: forestShadowColor,
                        fontWeight: 700,
                        textAlign: "center",
                        mb: 2,
                        mt: 1,
                      }}
                    >
                      !Successful Transaction
                    </Typography>
                    <Box
                      sx={{
                        borderBottom: `1px solid ${silverSageColor}`,
                        mx: "auto",
                        mb: 3,
                        overflowWrap: "break-word",
                        width: "fit-content",
                        maxWidth: 223,
                        opacity: state.confirmationPage ? 1 : 0,
                        transition: "1s all",
                      }}
                    >
                      <Typography
                        sx={{
                          textAlign: "center",
                          fontSize: 10,
                          fontWeight: 500,
                          color: forestShadowColor,
                        }}
                      >
                        {state.successfulMessage}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        my: 1,
                        backgroundColor: arcticMistColor,
                        display: "flex",
                        p: 1,
                        width: 221,
                        justifyContent: "space-between",
                        alignItems: "center",
                        borderRadius: 1,
                      }}
                    >
                      <Typography sx={{ fontSize: 10, fontWeight: 500 }}>
                        {user.first_name} {user.last_name}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: 10,
                          color: slateGrayColor,
                        }}
                      >
                        :Receiver
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        my: 1,
                        backgroundColor: arcticMistColor,
                        display: "flex",
                        p: 1,
                        width: 221,
                        justifyContent: "space-between",
                        alignItems: "center",
                        borderRadius: 1,
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: 10,
                          fontWeight: 500,
                        }}
                      >
                        {addBalanceSeparator(state.defaultAmount)}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: 10,
                          color: slateGrayColor,
                        }}
                      >
                        :Amount
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        my: 1,
                        backgroundColor: arcticMistColor,
                        display: "flex",
                        p: 1,
                        width: 221,
                        justifyContent: "space-between",
                        alignItems: "center",
                        borderRadius: 1,
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: 10,
                          fontWeight: 500,
                        }}
                      >
                        {moment().format("HH:mm - YYYY/MM/DD")}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: 10,
                          color: slateGrayColor,
                        }}
                      >
                        :Date
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        my: 1,
                        backgroundColor: arcticMistColor,
                        display: "flex",
                        p: 1,
                        width: 221,
                        justifyContent: "space-between",
                        alignItems: "center",
                        borderRadius: 1,
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: 10,
                          fontWeight: 500,
                        }}
                      >
                        #324231
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: 10,
                          color: slateGrayColor,
                        }}
                      >
                        :Number
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
          <Box sx={{ display: "flex", gap: 1, my: 2 }}>
            <Button
              text="پی لینک شخصی"
              color={
                state.paylinkType === "Personal" ? "arcticMist2" : "slateGray"
              }
              variant={
                state.paylinkType === "Personal" ? "contained" : "outlined"
              }
              sx={{
                border: `1px solid${
                  state.paylinkType === "Personal"
                    ? primaryColor
                    : silverSageColor
                }`,
              }}
              onClick={() => setState({ paylinkType: "Personal" })}
            />
            <Button
              text="پی لینک محصول"
              color={
                state.paylinkType === "Product" ? "arcticMist2" : "slateGray"
              }
              variant={
                state.paylinkType === "Product" ? "contained" : "outlined"
              }
              sx={{
                border: `1px solid${
                  state.paylinkType === "Product"
                    ? primaryColor
                    : silverSageColor
                }`,
              }}
              onClick={() => setState({ paylinkType: "Product" })}
            />
          </Box>
          <Box sx={{ mb: 2 }}>
            <AntTabs
              value={selectedTab}
              onChange={handleChange}
              aria-label="ant example"
            >
              <AntTab value="PaymentPage" label="صفحه پرداخت" />
              <AntTab value="ReceiptPage" label="صفحه فاکتور" />
            </AntTabs>
          </Box>
          {state.paylinkType === "Personal" ? (
            <PaylinkConfig
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
            />
          ) : (
            <ProductConfig
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
            />
          )}
        </Box>
        <Box
          sx={{
            position: "sticky",
            bottom: 0,
            left: 0,
            right: 0,
            p: 2,
            backgroundColor: whiteColor,
          }}
        >
          <Button
            text="ساخت پی لینک"
            onClick={
              state.paylinkType === "Personal"
                ? handleCreatePersoanlPaylink
                : handleCreateProductPaylink
            }
          />
        </Box>
      </Box>
    );
  };

  return (
    <Box sx={{ backgroundColor: silverSandColor, flex: 1, display: "flex" }}>
      {renderConfig()}
      {renderbusinessDescription}
      <Box
        sx={{
          flex: 1,
          display: { xs: "none", lg: "flex" },
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
          // height: `calc(100vh - ${appBarHeight + 2}px)`,
          // overflowY: "auto",
          // py: 2,
        }}
      >
        <Title
          text="پیش نمایش"
          containerProps={{ sx: { position: "absolute", top: 30, left: 21 } }}
        />
        {renderPreview()}
      </Box>
    </Box>
  );
};

export default Paylink;

const AntTabs = styled(Tabs)({
  borderBottom: "1px solid #e8e8e8",
  //   "& .MuiTabs-indicator": {
  //     backgroundColor: "#1890ff",
  //   },
});

const AntTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    padding: "10px 8px",
    fontSize: 12,
    textTransform: "none",
    minWidth: 0,
    [theme.breakpoints.up("sm")]: {
      minWidth: 0,
    },
    // fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    color: "rgba(0, 0, 0, 0.85)",
    // "&:hover": {
    //   color: "#40a9ff",
    //   opacity: 1,
    // },
    "&.Mui-selected": {
      //   color: "#1890ff",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#d1eaff",
    },
  })
);
