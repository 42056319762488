import React, { useEffect, useState } from "react";
import { MapContainer, Marker, TileLayer, useMapEvents } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import {
  Avatar,
  Box,
  Chip,
  SwipeableDrawer,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { drawerWidth } from "../../utils/constants";
import {
  arcticMistColor,
  forestShadowColor,
  goldenAmberColor,
  primaryColor,
} from "../../assets/colors";
import { ArrowDownIcon2, EuroIcon3, GlobeIcon } from "../../assets/icons";
import {
  getLastTransactionsRequestAction,
  getTransactionsCoordinatesRequestAction,
} from "../../redux/actions/transactionActions";
import { useSelector } from "react-redux";
import moment from "moment-jalaali";
import Title from "../../components/Title";
import CustomAccordion from "../../components/Accordion";
import { currencyIcon, typeStyles } from "../TransactionsList/constants";
import { numberWithCommas } from "../../utils/helpers";
import { statusObj } from "../Account/constants";
import { GET_LAST_TRANSACTIONS_STARTED } from "../../redux/actionTypes";
import useAppHooks from "../../hooks/useAppHooks";
import MarkerIcon from "./MarkerIcon";

const TransactionsMap = () => {
  const { dispatch, t } = useAppHooks();

  const { transactionsCoordinates, rType } = useSelector(
    (state) => state.transaction
  );
  const [open, setOpen] = useState(false);
  const [lastTransactions, setLastTransactions] = useState({
    transactions: [],
  });
  const loading = rType === GET_LAST_TRANSACTIONS_STARTED;

  const bounds = [
    [-85, -180], // Southwest corner (latitude, arbitrary longitude)
    [85, 180], // Northeast corner (latitude, arbitrary longitude)
  ];

  useEffect(() => {
    dispatch(getTransactionsCoordinatesRequestAction());
  }, []);

  const handleMarkerClick = (position) => {
    // setClickedMarkers({});
    // setClickedMarkers((prevState) => ({
    //   ...prevState,
    //   [index]: !prevState[index],
    // }));
    dispatch(
      getLastTransactionsRequestAction({
        countryCode: position.ip_location,
        setOpen,
        setLastTransactions,
      })
    );
  };

  const renderStatus = (status) => {
    switch (status) {
      case "Pending":
        return (
          <Chip
            sx={{ borderRadius: "7px" }}
            label={t("pendingApproval")}
            color="goldenCream"
          />
        );
      case "Approved":
        return (
          <Chip
            sx={{ borderRadius: "7px" }}
            label={t("confirmed")}
            color="emeraldOasis"
          />
        );
      case "Declined":
        return (
          <Chip
            sx={{ borderRadius: "7px" }}
            label={t("rejected")}
            color="rosyGlow"
          />
        );
    }
  };

  const handleMapClick = () => {
    setLastTransactions({ transactions: [] });
    setOpen(false);
  };

  const columns = [
    {
      field: "event", //access nested data with dot notation
      label: t("serviceType"),
    },
    {
      field: "amount",
      label: t("transactionAmount"),
      renderCell: (row) => numberWithCommas(Math.trunc(row.amount)),
    },
    {
      field: "currency",
      label: t("currencyUnit"),
    },

    {
      field: "status",
      label: t("status"),
      renderCell: (row) => renderStatus(row.status),
    },

    {
      field: "created_at",
      label: t("date"),
      renderCell: (row) => moment(row.date).format("HH:mm - jYYYY/jM/jD"),
    },
  ];

  const renderDrawer = () => {
    return (
      <SwipeableDrawer
        variant="persistent"
        anchor="bottom"
        open={open}
        onClose={() => {
          setLastTransactions({ transactions: [] });
          setOpen(false);
        }}
        onOpen={() => setOpen(true)}
        componentsProps={{
          backdrop: {
            sx: { left: { lg: drawerWidth }, backgroundColor: "transparent" },
          },
        }}
        PaperProps={{
          sx: {
            left: { lg: drawerWidth + 30 },
            right: { lg: 30 },
            borderTopRightRadius: { xs: 20, lg: 25 },
            borderTopLeftRadius: { xs: 20, lg: 25 },
            // p: 2,
            boxShadow: "0px -17px 44.3px 0px #7F91A133",
            pl: "21px",
            pr: "22px",
            pt: "37px",
            pb: "24px",
          },
        }}
      >
        <Box sx={{ display: { lg: "none" } }}>
          <Title
            text={t("details")}
            size={4}
            containerProps={{ sx: { ml: "5px", mb: "23px" } }}
          />
          <Box
            sx={{
              height: 41,
              p: 1,
              display: "flex",
              alignItems: "center",
              backgroundColor: arcticMistColor,
              borderRadius: 1.5,
              mb: "9px",
            }}
          >
            <Avatar
              sx={{ width: 24, height: 24 }}
              src={`https://flagcdn.com/${lastTransactions.country_code?.toLowerCase()}.svg`}
            />
            <Typography sx={{ fontSize: 12, mx: 1 }}>
              {t("countryName")}
            </Typography>
            <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
              {lastTransactions.country_name}
            </Typography>
          </Box>
          <Box
            sx={{
              height: 41,
              p: 1,
              display: "flex",
              backgroundColor: arcticMistColor,
              alignItems: "center",
              borderRadius: 1.5,
              // justifyContent: "space-between",
              mb: "9px",
            }}
          >
            <Box
              sx={{
                width: 9,
                height: 9,
                backgroundColor: primaryColor,
                borderRadius: "3px",
              }}
            ></Box>
            <Typography sx={{ fontSize: 12, ml: 1, flex: 1 }}>
              {t("transactionVolume")}
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography sx={{ fontWeight: 500, color: primaryColor }}>
                {lastTransactions.total_amount?.toFixed(2)}{" "}
              </Typography>
              <EuroIcon3
                width={12}
                height={12}
                style={{ marginRight: "3px" }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              height: 41,
              p: 1,
              display: "flex",
              backgroundColor: arcticMistColor,
              alignItems: "center",
              borderRadius: 1.5,
              // justifyContent: "space-between",
              mb: "29px",
            }}
          >
            <Box
              sx={{
                width: 9,
                height: 9,
                backgroundColor: goldenAmberColor,
                borderRadius: "3px",
              }}
            ></Box>
            <Typography sx={{ fontSize: 12, ml: 1, flex: 1 }}>
              {t("transactionCount")}
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                sx={{
                  fontWeight: 500,
                  color: goldenAmberColor,
                }}
              >
                {lastTransactions.total_count}{" "}
              </Typography>
              <Typography
                sx={{
                  fontSize: 8,
                  fontWeight: 500,
                  ml: 0.25,
                }}
              >
                {t("transaction")}
              </Typography>
            </Box>
          </Box>
          <Title
            text={t("recentTransactions")}
            size={4}
            containerProps={{ sx: { ml: "5px", mb: "18px" } }}
          />
          <CustomAccordion
            data={lastTransactions.transactions}
            columns={columns}
            renderSummary={(item) => {
              const amount = Math.trunc(item.amount);
              return (
                <>
                  <Box
                    sx={{
                      backgroundColor: arcticMistColor,
                      width: 36,
                      height: 36,
                      borderRadius: 100,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      mr: 1,
                    }}
                  >
                    <GlobeIcon height={20} width={20} />
                  </Box>
                  <Typography
                    sx={{
                      fontSize: 14,
                      fontWeight: 600,
                      display: "-webkit-box",
                      WebkitLineClamp: 1,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      WebkitBoxOrient: "vertical",
                      flex: 1,
                    }}
                  >
                    {item.event}
                  </Typography>
                  <ArrowDownIcon2
                    color={statusObj[item.status]?.color}
                    style={{ marginLeft: "3px" }}
                  />
                  <Typography
                    sx={{
                      // mx: 0.5,
                      color: statusObj[item.status]?.color,
                      fontWeight: 700,
                      fontSize: 14,
                    }}
                  >
                    {numberWithCommas(amount)}
                  </Typography>
                  {currencyIcon[item.currency]}
                </>
              );
            }}
          />
        </Box>
        <TableContainer sx={{ display: { xs: "none", lg: "block" } }}>
          <Table aria-label="simple table">
            {/* <TableHead>
              <TableRow>
                <TableCell>Dessert (100g serving)</TableCell>
                <TableCell align="right">Calories</TableCell>
                <TableCell align="right">Fat&nbsp;(g)</TableCell>
                <TableCell align="right">Carbs&nbsp;(g)</TableCell>
                <TableCell align="right">Protein&nbsp;(g)</TableCell>
              </TableRow>
            </TableHead> */}
            <TableBody>
              {[
                ...lastTransactions.transactions,
                ...Array(3 - lastTransactions.transactions.length).fill(null),
              ].map((row, index) => {
                let firstCell = null;
                switch (index) {
                  case 0:
                    firstCell = (
                      <Box
                        sx={{
                          height: 41,
                          p: 1,
                          display: "flex",
                          alignItems: "center",
                          backgroundColor: arcticMistColor,
                          borderRadius: 1.5,
                        }}
                      >
                        <Avatar
                          sx={{ width: 24, height: 24 }}
                          src={`https://flagcdn.com/${lastTransactions.country_code?.toLowerCase()}.svg`}
                        />
                        <Typography sx={{ fontSize: 12, mx: 1 }}>
                          {t("countryName")}
                        </Typography>
                        <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                          {lastTransactions.country_name}
                        </Typography>
                      </Box>
                    );
                    break;
                  case 1:
                    firstCell = (
                      <Box
                        sx={{
                          height: 41,
                          p: 1,
                          display: "flex",
                          backgroundColor: arcticMistColor,
                          alignItems: "center",
                          borderRadius: 1.5,
                        }}
                      >
                        <Box
                          sx={{
                            width: 9,
                            height: 9,
                            backgroundColor: primaryColor,
                          }}
                        ></Box>
                        <Typography sx={{ fontSize: 12, ml: 1, flex: 1 }}>
                          {t("transactionVolume")}
                        </Typography>
                        <Typography
                          sx={{ fontWeight: 500, color: primaryColor }}
                        >
                          {lastTransactions.total_amount?.toFixed(2)}{" "}
                        </Typography>
                        <EuroIcon3
                          width={12}
                          height={12}
                          style={{ marginRight: "3px" }}
                        />
                      </Box>
                    );
                    break;
                  case 2:
                    firstCell = (
                      <Box
                        sx={{
                          height: 41,
                          p: 1,
                          display: "flex",
                          backgroundColor: arcticMistColor,
                          alignItems: "center",
                          borderRadius: 1.5,
                          // justifyContent: "space-between",
                        }}
                      >
                        <Box
                          sx={{
                            width: 9,
                            height: 9,
                            backgroundColor: goldenAmberColor,
                            borderRadius: "3px",
                          }}
                        ></Box>
                        <Typography sx={{ fontSize: 12, ml: 1, flex: 1 }}>
                          {t("transactionCount")}
                        </Typography>
                        <Typography
                          sx={{
                            fontWeight: 500,
                            color: goldenAmberColor,
                          }}
                        >
                          {lastTransactions.total_count}{" "}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: 8,
                            fontWeight: 500,
                            ml: 0.25,
                          }}
                        >
                          {t("transaction")}
                        </Typography>
                      </Box>
                    );
                    break;
                }
                if (row === null) {
                  return (
                    <TableRow
                      key={`empty-${index}`}
                      // sx={{ "& > *": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row" sx={{ border: 0 }}>
                        {firstCell}
                      </TableCell>
                      <TableCell align="right" sx={{ border: 0 }}></TableCell>
                      <TableCell align="right" sx={{ border: 0 }}></TableCell>
                      <TableCell align="right" sx={{ border: 0 }}></TableCell>
                      <TableCell align="right" sx={{ border: 0 }}></TableCell>
                    </TableRow>
                  );
                }

                return (
                  <TableRow key={row.name}>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ width: 100, border: 0 }}
                    >
                      {firstCell}
                    </TableCell>
                    <TableCell align="left" sx={{ width: 80, border: 0 }}>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Box
                          sx={{
                            backgroundColor: arcticMistColor,
                            width: 36,
                            height: 36,
                            borderRadius: 100,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            mr: 1,
                          }}
                        >
                          <GlobeIcon height={20} width={20} />
                        </Box>
                        <Typography
                          sx={{
                            fontSize: 14,
                            fontWeight: 600,
                            display: "-webkit-box",
                            WebkitLineClamp: 1,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            WebkitBoxOrient: "vertical",
                            flex: 1,
                          }}
                        >
                          {row.event}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell align="left" sx={{ width: 80, border: 0 }}>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <ArrowDownIcon2
                          color={statusObj[row.status]?.color}
                          style={{ marginLeft: "3px" }}
                        />
                        <Typography
                          sx={{
                            // mx: 0.5,
                            color: statusObj[row.status]?.color,
                            fontWeight: 700,
                            fontSize: 14,
                          }}
                        >
                          {numberWithCommas(Math.trunc(row.amount))}
                        </Typography>
                        {currencyIcon[row.currency]}
                      </Box>

                      {/* {numberWithCommas(Math.trunc(row.amount))} */}
                    </TableCell>
                    <TableCell align="left" sx={{ width: 60, border: 0 }}>
                      {moment(row.date).format("HH:mm - jYYYY/jM/jD")}
                    </TableCell>
                    <TableCell align="left" sx={{ width: 180, border: 0 }}>
                      {renderStatus(row.status)}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </SwipeableDrawer>
    );
  };

  return (
    <Box
      sx={{
        height: "calc(100vh - 67px)",
        width: "100%",
        position: "relative",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: loading ? 999 : 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backdropFilter: loading ? "blur(10px)" : "blur(0px)",
          transition: "all 1s",
        }}
      ></Box>
      <MapContainer
        center={[45, 0]}
        minZoom={3}
        maxZoom={4}
        zoom={3}
        style={{ height: "calc(100vh - 67px)", width: "100%" }}
        zoomControl={false}
        maxBounds={bounds}
        maxBoundsViscosity={1.0}
        worldCopyJump={true} // Enable wrapping
        attributionControl={false}
      >
        <ChangeView onClick={handleMapClick} />
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        {transactionsCoordinates.map((position, idx) => (
          <Marker
            position={[position.lat, position.lon]}
            icon={MarkerIcon({
              color:
                lastTransactions.country_code === position.ip_location
                  ? primaryColor
                  : forestShadowColor,
              text: position.count,
            })}
            eventHandlers={{ click: () => handleMarkerClick(position) }}
          />
        ))}
      </MapContainer>
      {renderDrawer()}
    </Box>
  );
};

export default TransactionsMap;

function ChangeView({ onClick }) {
  const map = useMapEvents({
    click: onClick,
  });

  return null;
}
