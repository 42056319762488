import { Box, Typography } from "@mui/material";
import { LighthouseIcon } from "../../assets/icons";
import { arcticMistColor, primaryColor } from "../../assets/colors";
import Button from "../../components/Button";
import { useNavigate } from "react-router-dom";
import { ONBOARDING } from "../routes";
import useAppHooks from "../../hooks/useAppHooks";

const Welcome = () => {
  const { t, navigate } = useAppHooks();

  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box sx={{ textAlign: "center", mb: 10 }}>
        <Box
          sx={{
            width: 172,
            height: 172,
            backgroundColor: arcticMistColor,
            borderRadius: 100,
            overflow: "hidden",
            mb: "29px",
            mx: "auto",
          }}
        >
          <LighthouseIcon
            width={157.4}
            color={primaryColor}
            style={{ position: "relative", left: -10, bottom: -6 }}
          />
        </Box>
        <Typography sx={{ fontSize: 22, fontWeight: 600, mb: 2 }}>
          {t("welcomeToYekpay")}
        </Typography>
        <Typography sx={{ fontWeight: 500, mb: 3 }}>
          {t("letsGetStarted")}
        </Typography>
        <Button
          text={t("completeUserProfile")}
          sx={{ borderRadius: 100 }}
          onClick={() => navigate(ONBOARDING)}
        />
      </Box>
    </Box>
  );
};

export default Welcome;
