import { Avatar, Box, Divider, Typography } from "@mui/material";
import {
  aquaMist,
  arcticMistColor,
  brightCrimson,
  crimsonBlazeColor,
  deepBlue,
  errorColor,
  forestShadowColor,
  peachBlush,
  primaryColor,
  silverSageColor,
  slateGrayColor,
  whiteColor,
} from "../../assets/colors";
import {
  CardSendIcon,
  CheckIcon,
  CoinStackIcon,
  ErrIcon,
  EuroIcon,
  EuroIcon2,
  ExchangeIcon,
  LiraIcon,
  TimeLineIcon,
} from "../../assets/icons";
import Button from "../../components/Button";
import CurrencyInput from "../../components/CurrencyInput";
import { useEffect, useState } from "react";
import {
  addBalanceSeparator,
  numberToPersianWords,
  removeBalanceSeparator,
} from "../../utils/helpers";
import { useSelector } from "react-redux";
import { getBankCardsRequestAction } from "./../../redux/actions/userActions";
import {
  checkExchangeRequestAction,
  createExchangeRequestAction,
} from "../../redux/actions/virtualAccountsActions";
import {
  CHECK_EXCHANGE_STARTED,
  CREATE_EXCHANGE_STARTED,
} from "../../redux/actionTypes";
import moment from "moment-jalaali";
import { VIRTUAL_ACCOUNTS } from "../routes";
import useAppHooks from "../../hooks/useAppHooks";

const Exchange = () => {
  const {
    dispatch,
    navigate,
    t,
    params: { currencyCode },
  } = useAppHooks();
  const {
    virtualAccounts: { virtualAccounts, rType },
    user: { bankCards },
    auth: { user },
  } = useSelector((state) => ({
    virtualAccounts: state.virtualAccounts,
    auth: state.auth,
    user: state.user,
  }));
  let currencyId =
    currencyCode === "TRY" ? 18 : currencyCode === "EUR" ? 2 : "";

  let selectedWallet = virtualAccounts.find(
    (wallet) => wallet.currency_id === currencyId
  );

  const [amount, setAmount] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [errors, setErrors] = useState("");
  const [data, setData] = useState("");
  const [recieptData, setRecieptData] = useState("");

  useEffect(() => {
    dispatch(getBankCardsRequestAction());
  }, []);

  switch (activeStep) {
    case 0:
      return (
        <Box
          sx={{
            flex: 1,
            display: { lg: "flex" },
            justifyContent: "center",
            alignItems: "center",
            px: "15px",
            py: "24px",
          }}
        >
          <Box
            sx={{
              width: { lg: 691 },
              borderRadius: { xs: "24px", lg: "20px" },
              px: { xs: "12px ", lg: "26px" },
              py: "23px",
              backgroundColor: whiteColor,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", mb: "20px" }}>
              <Box
                sx={{
                  width: 40,
                  height: 40,
                  backgroundColor: arcticMistColor,
                  borderRadius: 100,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  mr: "10px",
                }}
              >
                <ExchangeIcon />
              </Box>
              <Typography sx={{ fontSize: 20, fontWeight: 600 }}>
                {t("convertCurrencyToRial", {
                  currency: currencyCode === "EUR" ? t("euro") : t("lira"),
                })}
              </Typography>
            </Box>
            <Box
              sx={{
                backgroundColor: arcticMistColor,
                borderRadius: "12px",
                mb: 2,
                px: { xs: "12px", lg: "19px" },
                py: { xs: "12px", lg: "15px" },
              }}
            >
              <Typography sx={{ fontWeight: 600, mb: "10px" }}>
                {t("notice")}
              </Typography>
              <Typography sx={{ fontSize: 14, lineHeight: 1.8 }}>
                {t("exchangeNotice")}
              </Typography>
            </Box>
            <Box sx={{ display: { lg: "flex" }, mb: "24px" }}>
              <CurrencyInput
                label={t("amount")}
                startAdornment={
                  currencyCode === "EUR" ? (
                    <EuroIcon width={23} color={primaryColor} />
                  ) : (
                    <LiraIcon width={23} color={primaryColor} />
                  )
                }
                value={amount}
                onValueChange={(values, sourceInfo) => {
                  console.log(values, sourceInfo);

                  setAmount(values.value);
                }}
                decimalScale={2}
                thousandSeparator
                error={errors.amount}
              />
              <Box sx={{ ml: { lg: "14px" } }}>
                <Box
                  sx={{
                    minWidth: 225,
                    mt: 3.5,
                    height: 45,
                    backgroundColor: arcticMistColor,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    // mb: 2,
                    px: 1,
                    borderRadius: "12px",
                    mb: "9px",
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box
                      sx={{
                        width: 30,
                        height: 30,
                        backgroundColor:
                          currencyCode === "EUR" ? deepBlue : brightCrimson,
                        borderRadius: 100,
                        //   display: "flex",
                        //   justifyContent: "center",
                        //   alignItems: "center",
                      }}
                    >
                      {currencyCode === "EUR" ? (
                        <EuroIcon
                          width={24}
                          height={24}
                          color={whiteColor}
                          style={{ marginRight: 3, marginTop: 3 }}
                        />
                      ) : (
                        <LiraIcon
                          width={24}
                          height={24}
                          color={whiteColor}
                          style={{ marginRight: 3, marginTop: 3 }}
                        />
                      )}
                    </Box>
                    <Typography
                      sx={{ fontSize: 12, color: slateGrayColor, ml: 1 }}
                    >
                      {t("balance")}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      sx={{
                        fontSize: 14,
                        fontWeight: 500,
                      }}
                    >
                      {addBalanceSeparator(Number(selectedWallet?.balance))}
                    </Typography>
                    {currencyCode === "EUR" ? (
                      <EuroIcon width={18} height={18} />
                    ) : (
                      <LiraIcon width={18} height={18} />
                    )}
                  </Box>
                </Box>
                <Box
                  sx={{
                    minWidth: 152,
                    height: 42,
                    border: `1px solid ${silverSageColor}`,
                    borderRadius: "12px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    ml: "auto",
                  }}
                >
                  <Typography sx={{ fontSize: 12, color: slateGrayColor }}>
                    {t("fee")}
                  </Typography>
                  <Typography sx={{ mx: 0.5 }}>{0.3}</Typography>
                  {currencyCode === "TRY" ? (
                    <LiraIcon width={18} />
                  ) : (
                    <EuroIcon width={18} />
                  )}
                </Box>
              </Box>
            </Box>
            {/* <Box sx={{ mb: 18, height: 21 }}>
              <Typography sx={{ color: errorColor, fontSize: 14 }}>
                {errors.amount}
              </Typography>
            </Box> */}
            <Typography
              sx={{
                fontSize: 14,
                color: crimsonBlazeColor,
                lineHeight: 1.8,
                opacity: errors.amount ? 1 : 0,
                transition: "all 0.25s",
                mb: 18,
              }}
            >
              <ErrIcon style={{ verticalAlign: "middle", marginLeft: 5 }} />
              {errors.amount}
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                text={t("back")}
                fullWidth={false}
                color="arcticMist"
                sx={{ width: 141 }}
                onClick={() => navigate(VIRTUAL_ACCOUNTS)}
              />
              <Button
                text={t("nextStep")}
                fullWidth={false}
                sx={{ width: 193 }}
                onClick={() =>
                  dispatch(
                    checkExchangeRequestAction({
                      amount,
                      fromCurrencyId: currencyCode === "EUR" ? 2 : 18,
                      toCurrencyId: 3,
                      setErrors,
                      setActiveStep,
                      setData,
                    })
                  )
                }
                loading={rType === CHECK_EXCHANGE_STARTED}
                disabled={amount.length === 0}
              />
            </Box>
          </Box>
        </Box>
      );
    case 1:
      return (
        <Box
          sx={{
            flex: 1,
            display: { lg: "flex" },
            justifyContent: "center",
            alignItems: "center",
            px: "15px",
            py: "16px",
          }}
        >
          <Box
            sx={{
              width: { lg: 691 },
              height: { lg: 596 },
              backgroundColor: whiteColor,
              pt: "36px",
              pb: { xs: "16px", lg: "23px" },
              px: { xs: "12px", lg: "36px" },
              borderRadius: "20px",
              position: "relative",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", mb: "20px" }}>
              <Box
                sx={{
                  width: 40,
                  height: 40,
                  backgroundColor: arcticMistColor,
                  borderRadius: 100,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  mr: "10px",
                }}
              >
                <ExchangeIcon />
              </Box>
              <Typography sx={{ fontSize: 20, fontWeight: 600 }}>
                {t("conversionConfirmation")}
              </Typography>
            </Box>
            <Box
              sx={{
                backgroundColor: arcticMistColor,
                borderRadius: "12px",
                mb: 1,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  px: "19px",
                  pt: "15px",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box
                    sx={{
                      width: 30,
                      height: 30,
                      backgroundColor:
                        currencyCode === "EUR" ? deepBlue : brightCrimson,
                      borderRadius: 100,
                      //   display: "flex",
                      //   justifyContent: "center",
                      //   alignItems: "center",
                    }}
                  >
                    {currencyCode === "EUR" ? (
                      <EuroIcon
                        width={24}
                        height={24}
                        color={whiteColor}
                        style={{ marginRight: 3, marginTop: 2.5 }}
                      />
                    ) : (
                      <LiraIcon
                        width={24}
                        height={24}
                        color={whiteColor}
                        style={{ marginRight: 3, marginTop: 2.5 }}
                      />
                    )}
                  </Box>
                  <Typography
                    sx={{
                      fontSize: 12,
                      ml: 1,
                      fontWeight: 500,
                    }}
                  >
                    {currencyCode === "EUR" ? t("euro") : t("lira")}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    sx={{
                      fontWeight: 500,
                    }}
                  >
                    {addBalanceSeparator(amount)}
                  </Typography>
                  {currencyCode === "EUR" ? (
                    <EuroIcon width={18} />
                  ) : (
                    <LiraIcon width={18} />
                  )}
                </Box>
              </Box>
              <Divider sx={{ borderColor: silverSageColor }}>
                <ExchangeIcon
                  width={24}
                  height={24}
                  style={{ transform: "rotate(-45deg)", marginTop: 6 }}
                />
              </Divider>
              <Box
                sx={{
                  display: "flex",
                  px: "19px",
                  pb: "10px",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Avatar
                    src="https://flagcdn.com/ir.svg"
                    sx={{ width: 30, height: 30 }}
                  />
                  <Typography
                    sx={{
                      fontSize: 12,
                      fontWeight: 500,
                      ml: 1,
                    }}
                  >
                    {t("rial")}
                  </Typography>
                </Box>
                <Typography sx={{ fontSize: 20, fontWeight: 500 }}>
                  {data.to_amount}{" "}
                  <Typography
                    sx={{
                      color: primaryColor,
                      display: "inline-block",
                      fontWeight: 400,
                      fontSize: 12,
                    }}
                  >
                    {t("rialSpecial")}
                  </Typography>
                </Typography>
              </Box>
            </Box>
            <Typography sx={{ mb: 31, fontSize: 12, color: slateGrayColor }}>
              {t("depositAmount", {
                amount: numberToPersianWords(
                  Math.floor(removeBalanceSeparator(data.to_amount) / 10)
                ),
              })}
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mt: "auto",
              }}
            >
              <Button
                text={t("back")}
                fullWidth={false}
                color="arcticMist"
                sx={{ width: 141 }}
                onClick={() => setActiveStep(0)}
              />
              <Button
                text={t("confirmAndConvert")}
                fullWidth={false}
                sx={{ width: 193 }}
                onClick={() =>
                  dispatch(
                    createExchangeRequestAction({
                      fromCurrencyId: data.from_currency_id,
                      toCurrencyId: data.to_currency_id,
                      setActiveStep,
                      setRecieptData,
                      amount,
                    })
                  )
                }
                loading={rType === CREATE_EXCHANGE_STARTED}
              />
            </Box>
          </Box>
        </Box>
      );
    case 2:
      return (
        <Box
          sx={{
            flex: 1,
            display: { lg: "flex" },
            justifyContent: "center",
            alignItems: "center",
            px: "15px",
            py: "16px",
          }}
        >
          <Box
            sx={{
              width: { lg: 691 },
              height: { lg: 596 },
              backgroundColor: whiteColor,
              pt: "36px",
              pb: { xs: "16px", lg: "23px" },
              px: { xs: "12px", lg: "36px" },
              borderRadius: "20px",
              position: "relative",
            }}
          >
            <Button
              text={t("backToAccounts")}
              startIcon={<CoinStackIcon />}
              sx={{
                position: "absolute",
                top: 26,
                left: 36,
                color: forestShadowColor,
                display: { xs: "none", lg: "flex" },
              }}
              variant="outlined"
              fullWidth={false}
              color="silverSage"
              onClick={() => navigate(VIRTUAL_ACCOUNTS)}
            />
            <Box sx={{ textAlign: "center", width: { lg: 380 }, m: "auto" }}>
              <Box
                sx={{
                  width: 68,
                  height: 68,
                  backgroundColor: aquaMist,
                  borderRadius: 100,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  mx: "auto",
                  mb: "27px",
                }}
              >
                <CheckIcon width={40.8} height={40.8} color={primaryColor} />
              </Box>
              <Typography sx={{ fontWeight: 700, mb: 2 }}>
                {t("rialConversionCompleted")}
              </Typography>
              <Typography sx={{ fontSize: 14, color: slateGrayColor, mb: 2 }}>
                {t("amountDepositedToVirtualAccount", {
                  amount: data.to_amount,
                })}
              </Typography>
              <Box
                sx={{
                  backgroundColor: peachBlush,
                  width: 200,
                  mx: "auto",
                  height: 33,
                  mb: "27px",
                  display: "flex",
                  alignItems: "center",
                  px: 1,
                  borderRadius: "4px",
                }}
              >
                <TimeLineIcon />
                <Typography sx={{ fontSize: 14, fontWeight: 500, ml: 1 }}>
                  {t("dateColon")} {moment().format("HH:mm - YYYY/MM/DD")}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  // justifyContent: "space-between",
                  backgroundColor: arcticMistColor,
                  height: 42,
                  px: 1,
                  alignItems: "center",
                  borderRadius: "8px",
                  mb: 1,
                }}
              >
                <Typography>{t("amountColon")}</Typography>
                <Typography sx={{ ml: "auto" }}>{data.amount}</Typography>
                {currencyCode === "TRY" ? (
                  <LiraIcon width={18} />
                ) : (
                  <EuroIcon2 width={18} />
                )}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  // justifyContent: "space-between",
                  backgroundColor: arcticMistColor,
                  height: 42,
                  px: 1,
                  alignItems: "center",
                  borderRadius: "8px",
                  mb: 1,
                }}
              >
                <Typography>{t("convertedAmount")}</Typography>
                <Typography sx={{ ml: "auto", mr: 0.5 }}>
                  {data.to_amount}
                </Typography>
                <Typography
                  sx={{ color: primaryColor, display: "inline-block" }}
                >
                  {t("rialSpecial")}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  // justifyContent: "space-between",
                  backgroundColor: arcticMistColor,
                  height: 42,
                  px: 1,
                  alignItems: "center",
                  borderRadius: "8px",
                  mb: 1,
                }}
              >
                <Typography>{t("fee")}</Typography>
                <Typography sx={{ ml: "auto" }}>0.3</Typography>
                {currencyCode === "TRY" ? (
                  <LiraIcon width={18} />
                ) : (
                  <EuroIcon2 width={18} />
                )}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  backgroundColor: arcticMistColor,
                  height: 42,
                  px: 1,
                  alignItems: "center",
                  borderRadius: "8px",
                  mb: 1,
                }}
              >
                <Typography>{t("trackingNumber")}</Typography>
                <Typography>{recieptData}</Typography>
              </Box>
              {/* <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  backgroundColor: arcticMistColor,
                  height: 42,
                  px: 1,
                  alignItems: "center",
                  borderRadius: "8px",
                  mb: 1,
                }}
              >
                <Typography>{t("trackingNumber")}</Typography>
                <Typography>#2567434878</Typography>
              </Box> */}
            </Box>
          </Box>
        </Box>
      );
  }
};

export default Exchange;
