import {
  Avatar,
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Link,
  SwipeableDrawer,
  Typography,
  alpha,
} from "@mui/material";
import {
  blackColor,
  forestShadowColor,
  midnightBlueColor,
  primaryColor,
  sageGreenColor,
  silverSageColor,
  whiteColor,
} from "../../../assets/colors";
import Wave from "../wave.svg";
import Background from "../background.png";
import {
  ArrowRightIcon,
  EmailIcon,
  GoogleIcon,
  LogoText,
  PhoneHangupIcon,
} from "../../../assets/icons";
import Button from "../../../components/Button";
import CustomTextInput from "../../../components/CustomTextInput";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MaterialUISwitch from "../../../components/Switch";
import CustomOtpInput from "../../../components/CustomOtpInput";
import { useDispatch, useSelector } from "react-redux";
import { formatTime } from "../../../utils/helpers";
import {
  REGISTER_USER_STARTED,
  VERIFY_STARTED,
} from "../../../redux/actionTypes";
import {
  registerUserRequestAction,
  resendRegisterCodeRequestAction,
  verifyRequestAction,
} from "../../../redux/actions/authActions";
import OtpInput from "../../../components/CustomOtpInput2";
import { fullHeight } from "../../../utils/constants";
import { LOGIN } from "../../routes";
import useAppHooks from "../../../hooks/useAppHooks";
// --------------------------------------------------------------------------------
const Register = () => {
  const { dispatch, t, navigate } = useAppHooks();

  const { rType } = useSelector((state) => ({
    rType: state.auth.rType,
  }));

  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [mobile, setMobile] = useState("");
  const [referralCodeIsActive, setReferralCodeIsActive] = useState(false);
  const [promotionCode, setPromotionCode] = useState("");
  const [acceptTermAndConditions, setAcceptTermAndConditions] = useState(true);
  const [errors, setErrors] = useState({});
  const [step, setStep] = useState(0);
  const [otp, setOtp] = useState("");
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    document
      .querySelector('meta[name="theme-color"]')
      .setAttribute("content", primaryColor);
  }, []);

  useEffect(() => {
    if (otp.length === 5) {
      onVerify();
    }
  }, [otp]);

  useEffect(() => {
    let timer = null;
    timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);

    return () => clearInterval(timer);
  }, [counter, step]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const errorObj = {};

    if (firstname === "") errorObj.firstname = t("pleaseEnterYourName");
    if (lastname === "") errorObj.lastname = t("pleaseEnterYourLastName");
    if (mobile.length < 10) errorObj.mobile = t("invalidMobileNumber");
    if (mobile === "") errorObj.mobile = t("pleaseEnterYourMobileNumber");
    if (referralCodeIsActive && promotionCode.length < 5)
      errorObj.promotionCode = t("pleaseEnterYourReferralCode");

    setErrors(errorObj);

    if (Object.keys(errorObj).length > 0) return;

    let data = {
      firstname,
      lastname,
      mobile,
      setErrors,
      setStep,
      setCounter,
    };
    if (referralCodeIsActive) data.promotionCode = promotionCode;
    dispatch(registerUserRequestAction(data));
  };

  const onVerify = (e) => {
    e?.preventDefault();
    const errorObj = {};

    if (otp === "") errorObj.otp = t("pleaseEnterTheSentCode");

    setErrors(errorObj);

    if (Object.keys(errorObj).length > 0) return;

    dispatch(verifyRequestAction({ verify_token: otp, mobile }, { setErrors }));
  };

  const renderStep = () => {
    switch (step) {
      case 0:
        return (
          <>
            <Typography
              sx={{ fontSize: 28, fontWeight: 700, mb: 2, textAlign: "center" }}
            >
              {t("userRegistration")}
            </Typography>
            <Typography
              sx={{ color: sageGreenColor, textAlign: "center", mb: 3 }}
            >
              {t("pleaseCompleteTheInformationForRegistration")}
            </Typography>
            <Box sx={{ mb: 1, display: "flex", alignItems: "center" }}>
              <CustomTextInput
                label={t("mobileNumber")}
                value={mobile}
                placeholder={t("enterYourMobileWithoutZero")}
                sx={{ height: 56, direction: "rtl" }}
                number
                onChange={(e) => {
                  let value = e.target.value;

                  setMobile(value.startsWith("0") ? value.substring(1) : value);
                  setErrors({ ...errors, mobile: "" });
                }}
                maxLength={10}
                error={errors.mobile}
                helperText={errors.mobile}
              />
              <Box
                sx={{
                  width: 82,
                  border: `1.5px solid ${silverSageColor}`,
                  display: "flex",
                  alignItems: "center",
                  height: 50,
                  borderRadius: "8px",
                  mt: 1,
                  ml: 1,
                  justifyContent: "center",
                }}
              >
                <Typography sx={{ fontSize: 14, mr: 1 }}>98+</Typography>
                <Avatar
                  src={`https://flagcdn.com/ir.svg`}
                  sx={{ width: 18, height: 18, mb: 0.5 }}
                />
              </Box>
            </Box>
            <Box sx={{ mb: 1 }}>
              <CustomTextInput
                label={t("firstName")}
                value={firstname}
                placeholder={t("enterYourName")}
                sx={{ height: 56, direction: "rtl" }}
                onChange={(e) => setFirstname(e.target.value)}
                // maxLength={10}
                error={errors.firstname}
                helperText={errors.firstname}
                persian
              />
            </Box>
            <Box sx={{ mb: 1 }}>
              <CustomTextInput
                label={t("lastName")}
                value={lastname}
                placeholder={t("enterYourLastName")}
                sx={{ height: 56, direction: "rtl" }}
                onChange={(e) => setLastname(e.target.value)}
                error={errors.lastname}
                helperText={errors.lastname}
                persian
              />
            </Box>
            <FormGroup>
              <FormControlLabel
                control={
                  <MaterialUISwitch
                    sx={{
                      mr: "auto",
                      "& .MuiSwitch-thumb": { backgroundColor: "#6F7673" },
                    }}
                    onChange={(e) => setReferralCodeIsActive(e.target.checked)}
                  />
                }
                label={t("referralCode")}
                sx={{ ml: 0, mb: 3 }}
                labelPlacement="start"
                componentsProps={{ typography: { fontWeight: 700 } }}
              />
              <OtpInput
                label={t("enterReferralCode")}
                length={5}
                onChange={(e) => setPromotionCode(e)}
                error={errors.promotionCode}
                helperText={errors.promotionCode}
                containerProps={{
                  sx: {
                    position: "relative",
                    top: referralCodeIsActive ? 0 : -55,
                    visibility: referralCodeIsActive ? "visible" : "hidden",
                    opacity: referralCodeIsActive ? 1 : 0,
                    transition: "1s all",
                    height: referralCodeIsActive ? 111.91 : 0,
                  },
                }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={acceptTermAndConditions}
                    onChange={(e) =>
                      setAcceptTermAndConditions(e.target.checked)
                    }
                  />
                }
                label={
                  <>
                    <Link
                      href="https://yekpay.com/user-agreement/"
                      target="_blank"
                      underline="hover"
                    >
                      {t("termsAndConditions")}
                    </Link>{" "}
                    {t("acceptYekpay")}
                  </>
                }
                sx={{ mb: 2 }}
                componentsProps={{
                  typography: { sx: { fontSize: 14, color: sageGreenColor } },
                }}
              />
            </FormGroup>
            <Button
              text={t("registerAccount")}
              sx={{ borderRadius: "58px", width: { lg: 361 }, py: 1.6, mb: 2 }}
              onClick={handleSubmit}
              disabled={!acceptTermAndConditions}
              loading={rType === REGISTER_USER_STARTED}
              type="submit"
            />
            {/* <Button
              text="ثبت نام با حساب گوگل"
              sx={{
                borderRadius: "58px",
                width: 361,
                py: 1.6,
                mb: 2,
              }}
              variant="outlined"
              // color="secondary"
              startIcon={<GoogleIcon />}
              disabled={!acceptTermAndConditions}
            /> */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                mb: 2,
              }}
            >
              <Typography sx={{ color: blackColor }}>
                {t("haveAnAccount")}
              </Typography>
              <Button
                variant="text"
                text={t("logIn")}
                fullWidth={false}
                onClick={() => navigate(LOGIN)}
              />
            </Box>
          </>
        );
      case 1:
        return (
          <>
            <Typography
              sx={{ fontSize: 28, fontWeight: 700, mb: 2, textAlign: "center" }}
            >
              {t("enterVerificationCode")}
            </Typography>
            <Typography
              sx={{ color: sageGreenColor, textAlign: "center", mb: 2.75 }}
            >
              {t("enterTheVerificationCodeSentToYourMobile")}
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "center", mb: 1.5 }}>
              <CustomOtpInput
                length={5}
                value={otp}
                onComplete={(value) => setOtp(value)}
                error={errors.otp}
                helperText={errors.otp}
              />
            </Box>
            <Typography
              sx={{
                fontSize: 14,
                color: sageGreenColor,
                textAlign: "center",
                mb: 20,
              }}
            >
              <Link
                component="button"
                underline="none"
                disabled={counter > 0}
                onClick={() =>
                  dispatch(
                    resendRegisterCodeRequestAction({ mobile, setCounter })
                  )
                }
                sx={{
                  color: forestShadowColor,
                  ":disabled": {
                    color: "inherit",
                    cursor: "default",
                  },
                }}
              >
                {t("resendCode")}
              </Link>
              {counter > 0 ? `: ${formatTime(counter)}` : null}
            </Typography>{" "}
            <Button
              text={t("login")}
              sx={{ borderRadius: 2.5, width: { lg: 361 }, py: 1.6, mb: 2 }}
              onClick={onVerify}
              loading={rType === VERIFY_STARTED}
              type="submit"
            />
            <Button
              variant="text"
              text={t("goBack")}
              sx={{
                borderRadius: "58px",
                width: { lg: 361 },
                py: 1.6,
                mb: 2,
                fontSize: 16,
              }}
              onClick={() => setStep(0)}
              startIcon={<ArrowRightIcon />}
              color="black"
            />
          </>
        );
    }
  };

  const renderDrawer = (
    <SwipeableDrawer
      variant="persistent"
      anchor="bottom"
      open
      PaperProps={{
        sx: {
          borderTop: 0,
          backgroundColor: primaryColor,
        },
      }}
      sx={{ display: { lg: "none" } }}
    >
      <Box
        sx={{
          minHeight: 200,
          position: "relative",
          overflow: "hidden",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          "&::before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundImage: `url(${Background})`,
            backgroundPositionY: -15,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            transform: "scale(1.6)",
            zIndex: -1,
          },
        }}
      >
        <LogoText color={whiteColor} height={51} />
      </Box>
      <Box
        sx={{
          px: "25px",
          pt: "29px",
          borderTopLeftRadius: 20,
          borderTopRightRadius: 20,
          backgroundColor: whiteColor,
          // height: fullHeight
        }}
        role="presentation"
        // onClick={toggleDrawer(false)}
        // onKeyDown={toggleDrawer(false)}
      >
        {renderStep()}
      </Box>
    </SwipeableDrawer>
  );

  return (
    <Box
      sx={{
        backgroundColor: primaryColor,
        minHeight: fullHeight,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        // overflow: "hidden",
        backgroundImage: `url(${Background})`,
        backgroundPositionY: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <Box
        sx={{
          width: 495,
          // height: 730,
          backgroundColor: whiteColor,
          borderRadius: 5,
          boxShadow: "0px 0px 40px 0px #0000000F",
          zIndex: 1,
          //   p: 2,
          mt: 1,
          mb: 10,
          display: { xs: "none", lg: "block" },
        }}
      >
        <Box sx={{ mx: "auto", width: 361 }} component="form">
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <LogoText
              color={primaryColor}
              width="73.79"
              style={{
                marginTop: "40px",
                marginBottom: "35px",
              }}
            />
          </Box>
          {renderStep()}
        </Box>
      </Box>
      <Wave style={{ position: "absolute", right: 0, left: 0, bottom: 0 }} />
      <Box
        sx={{
          position: "absolute",
          right: 0,
          left: 0,
          bottom: 0,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "row-reverse",
            alignItems: "center",
            pb: 1,
            color: alpha(midnightBlueColor, 0.7),
            // opacity: 0.7,
          }}
        >
          <Typography sx={{ ml: 1, color: "inherit" }}>
            {t("phoneNumber")}
          </Typography>
          <PhoneHangupIcon color="inherit" />
          <Typography sx={{ mx: 1, color: "inherit" }}>
            {t("supportEmail")}
          </Typography>
          <EmailIcon color="inherit" />
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center", pb: 1 }}>
          <Typography
            sx={{ fontSize: 14, color: sageGreenColor, fontWeight: 300 }}
          >
            {t("rightsReserved")}
          </Typography>
        </Box>
      </Box>
      {renderDrawer}
    </Box>
  );
};

export default Register;
