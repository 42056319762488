import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import faTranslations from "../locales/fa.json";
// import ruTranslations from "../locales/ru.json";
// import trTranslations from "../locales/tr.json";
import LanguageDetector from "i18next-browser-languagedetector";

const resources = {
  fa: { translation: faTranslations },
};

const DETECTION_OPTIONS = {
  order: ["localStorage", "navigator"],
  caches: ["localStorage"],
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    detection: DETECTION_OPTIONS,
    fallbackLng: "fa",
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
