import L from "leaflet";
import i18next from "i18next";

const MarkerIcon = ({ text, color }) => {
  return L.divIcon({
    html: `<svg width="68" height="74" viewBox="0 0 68 74" fill="none" xmlns="http://www.w3.org/2000/svg"><g filter="url(#filter0_f_129_3772)"><rect x="18.468" y="42" width="31" height="14" rx="7" fill="${color}"/></g><path d="M36.5385 0H31.782C22.1894 0 17.3931 0 13.9584 2.32095C12.5246 3.28982 11.2898 4.52459 10.321 5.9584C8 9.3931 8 14.1894 8 23.7821C8 33.3747 8 38.171 10.321 41.6057C11.2898 43.0395 12.5246 44.2743 13.9584 45.2432C17.3931 47.5641 22.1894 47.5641 31.782 47.5641H36.5385C46.1311 47.5641 50.9274 47.5641 54.3621 45.2432C55.7959 44.2743 57.0307 43.0395 57.9996 41.6057C60.3205 38.171 60.3205 33.3747 60.3205 23.7821C60.3205 14.1894 60.3205 9.3931 57.9996 5.9584C57.0307 4.52459 55.7959 3.28982 54.3621 2.32095C50.9274 0 46.1311 0 36.5385 0Z" fill="${color}"/><path d="M32.0799 51.2596L28.5098 47.6894C26.9591 46.1387 28.0574 43.4873 30.2504 43.4873H37.3906C39.5836 43.4873 40.6819 46.1387 39.1312 47.6894L35.5611 51.2596C34.5998 52.2208 33.0412 52.2208 32.0799 51.2596Z" fill="${color}"/><text x="33" y="20" text-anchor="middle" fill="white" font-family="IRANSansXFaNum">${text}</text><text x="33" y="35" text-anchor="middle" fill="white" font-family="IRANSansXFaNum">${i18next.t(
      "transaction"
    )}</text><defs><filter id="filter0_f_129_3772" x="0.567957" y="24.1" width="66.8" height="49.8" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/><feGaussianBlur stdDeviation="8.95" result="effect1_foregroundBlur_129_3772"/></filter></defs></svg>`,
    iconSize: [25, 41],
    iconAnchor: [-10, 60],
    popupAnchor: [1, -34],
    className: "custom-icon", // you can use this class to style your icon
  });
};

export default MarkerIcon;
