import React, { createElement, useState } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import MuiTableRow from "@mui/material/TableRow";
import MuiTableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { Chip, LinearProgress } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import TableRow from "./Row";
import { CsvBuilder } from "filefy";
import jsPDF from "jspdf";
import "jspdf-autotable";
import usePagination from "@mui/material/usePagination";
// import CustomDropDown from "../CustomDropDown";
import { ListIcon } from "../../assets/icons";
import Pagination from "@mui/material/Pagination";
import MuiPaginationItem from "@mui/material/PaginationItem";
import {
  arcticMistColor,
  forestShadowColor,
  primaryColor,
  silverMistColor,
  silverSageColor,
  slateGrayColor,
  whisperingSnowColor,
  whiteColor,
} from "../../assets/colors";
import { PDFDownloadLink } from "@react-pdf/renderer";

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function EnhancedTable({
  title,
  columns,
  data,
  toolbar,
  count,
  onChangePage,
  onChangeRowsPerPage,
  loading,
  page,
  from,
  to,
  emptyIcon,
  emptyMessage,
}) {
  // const { t } = useTranslation();

  const [rowPerPage, setRowPerPage] = useState(`20 items`);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  let numberPage = Math.ceil(count / 10);
  const { items } = usePagination({
    count: numberPage,
  });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const exportTableCSV = () => {
    let filteredColumns = columns.filter((column) => "field" in column);

    let columnData = filteredColumns.map((c) => c.label);
    let rowData = data.map((data) =>
      filteredColumns.map((column) => data[column.field])
    );

    let csvBuilder = new CsvBuilder(
      `${title ? `${title.split(" ").join("")}_` : ""}${from}_${to}.csv`
    )
      .setDelimeter(",")
      .setColumns(columnData)
      .addRows(rowData)
      .exportFile();

    setAnchorEl(null);
  };

  const exportTablePDF = () => {
    let filteredColumns = columns.filter((column) => "field" in column);

    let columnData = filteredColumns.map((c) => c.label);
    let rowData = data.map((row) =>
      filteredColumns.map((column) => row[column.field])
    );

    let doc = new jsPDF("l", "mm", [297, 210]);
    doc.autoTable({
      head: [columnData],
      body: rowData,
      theme: "grid",
    });

    doc.save(`transactions_list.pdf`);
    setAnchorEl(null);
  };

  return (
    <>
      <TableContainer
        component={Paper}
        variant="outlined"
        sx={{ borderColor: whisperingSnowColor, borderRadius: 2.5 }}
      >
        <Box
          sx={(theme) => ({
            px: 2,
            // display: "flex",
            backgroundColor: "inherit",
            borderRadius: "4px",
            flexDirection: "row",
            gap: "16px",
            justifyContent: "space-between",
            // padding: "24px 16px",
            "@media max-width: 768px": {
              flexDirection: "column",
            },
          })}
        >
          <Box sx={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <ListIcon color={primaryColor} style={{ marginLeft: 10 }} />
              <Typography sx={{ fontSize: 18, fontWeight: 600 }}>
                {title}
              </Typography>
            </Box>
            {toolbar}
            <Chip
              id="basic-button"
              // variant="outlined"
              label="Excel"
              onClick={exportTableCSV}
              // icon={<DocumentDownloadIcon />}
              sx={{
                borderRadius: "6px",
                fontSize: 12,
                // fontWeight: 600,
                height: 40,
                px: 1,
                mr: 1,
              }}
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              color="primary"
            />
            <Chip
              id="basic-button"
              // variant="outlined"
              label="PDF"
              onClick={exportTablePDF}
              // icon={<DocumentDownloadIcon />}
              sx={{
                borderRadius: "6px",
                fontSize: 12,
                fontWeight: 300,
                height: 40,
                px: 1,
              }}
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              color="primary"
            />
            {/* <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem onClick={exportTableCSV}>csv</MenuItem>
                <MenuItem
                  component={PDFDownloadLink}
                  // onClick={exportTablePDF}
                  // document={
                  //   <PdfTemplate
                  //     data={{
                  //       title,
                  //       columns: columns.filter(
                  //         (column) => !column.hasOwnProperty("export")
                  //       ),
                  //       items: data,
                  //       from,
                  //       to,
                  //     }}
                  //   />
                  // }
                  fileName={`${
                    title ? `${title.split(" ").join("")}_` : ""
                  }${from}_${to}`}
                >
                  pdf
                </MenuItem>
              </Menu> */}
          </Box>
        </Box>
        <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
          <TableHead>
            <MuiTableRow>
              {columns.map((headCell) => (
                <MuiTableCell
                  key={headCell.id}
                  align={headCell.numeric ? "right" : "center"}
                  padding={headCell.disablePadding ? "none" : "normal"}
                  sortDirection={orderBy === headCell.id ? order : false}
                  sx={{
                    fontSize: 12,
                    color: slateGrayColor,
                    borderBottom: "none",
                    minWidth: headCell.minWidth,
                    backgroundColor: arcticMistColor,
                  }}
                >
                  {headCell.label}
                </MuiTableCell>
              ))}
            </MuiTableRow>
          </TableHead>

          {data.length === 0 ? (
            <TableBody>
              <MuiTableRow>
                <MuiTableCell
                  colSpan={columns?.length}
                  sx={{
                    borderColor: silverSageColor,
                    textAlign: "center",
                    py: 6,
                  }}
                >
                  <Box>
                    {emptyIcon &&
                      createElement(emptyIcon, {
                        style: {
                          marginBottom: 20,
                          color: alpha(forestShadowColor, 0.2),
                        },
                      })}
                    <Typography sx={{ fontSize: 20, fontWeight: 600 }}>
                      {emptyMessage}
                    </Typography>
                  </Box>
                </MuiTableCell>
              </MuiTableRow>
            </TableBody>
          ) : (
            <TableBody
            // component={Paper}
            // sx={{
            //   boxShadow: "0 0 0 1px #F2F3F7",
            //   borderRadius: "50%",
            //   border: "1px solid #F2F3F7",
            //   overflow: "hidden",
            // }}
            >
              {loading ? (
                <MuiTableRow>
                  <MuiTableCell
                    colSpan={columns?.length}
                    sx={{ borderBottom: "none", p: 0 }}
                  >
                    <LinearProgress />
                  </MuiTableCell>
                </MuiTableRow>
              ) : (
                <MuiTableRow>
                  <MuiTableCell
                    colSpan={columns?.length}
                    sx={{ borderBottom: "none", p: 0.25 }}
                  ></MuiTableCell>
                </MuiTableRow>
              )}
              {data.map((r, index) => (
                <TableRow
                  columns={columns}
                  data={{ ...r, rowIndex: index }}
                  rowsCount={data.length}
                />
              ))}
            </TableBody>
          )}
        </Table>
        <Box sx={{ p: 2, display: "flex", justifyContent: "space-between" }}>
          <Typography sx={{ color: slateGrayColor, fontSize: 12 }}>
            نمایش{" "}
            {Math.min(
              count,
              rowPerPage.replace("items", "") * page -
                (rowPerPage.replace("items", "") - data.length)
            )}{" "}
            آیتم از {count} آیتم جدول
          </Typography>
          <Pagination
            variant="outlined"
            count={Math.ceil(count / rowPerPage.replace("items", ""))}
            page={page}
            // siblingCount={0}
            onChange={(event, value) => onChangePage(value)}
            renderItem={(item) => (
              <PaginationItem
                {...item}
                // slots={{
                //   previous: () => (
                //     <ArrowDownIcon style={{ transform: "rotate(90deg)" }} />
                //   ),
                //   next: () => (
                //     <ArrowDownIcon style={{ transform: "rotate(-90deg)" }} />
                //   ),
                // }}
                rowPerPage={rowPerPage.replace("items", "")}
                count={count}
              />
            )}
            sx={{
              "& .MuiPagination-ul": {
                flexDirection: "row-reverse",
              },
            }}
          />
        </Box>
      </TableContainer>
    </>
  );
}

const PaginationItem = styled(MuiPaginationItem)((props) => {
  return {
    "&.MuiPaginationItem-root": {
      // flexDirection: "row-reverse",
      height: 27,
      minWidth: 27,
      borderColor: silverMistColor,
      fontWeight: 600,
      // backgroundColor: silverCloudColor,
      // margin: 0,
      // borderRadius: 0,
      // borderTopLeftRadius: props.page === 1 ? 38 : 0,
      // borderBottomLeftRadius: props.page === 1 ? 38 : 0,
      // borderTopRightRadius:
      //   props.page === Math.ceil(props.count / props.rowPerPage) ? 38 : 0,
      // borderBottomRightRadius:
      //   props.page === Math.ceil(props.count / props.rowPerPage) ? 38 : 0,
      fontSize: 11,
      "&.Mui-selected": {
        backgroundColor: primaryColor,
        color: whiteColor,
        // boxShadow: "0px 0px 4.6px 0px rgba(65, 173, 206, 0.76);",
        // borderRadius: 5,
        // height: 27,
        // width: 27,
      },
    },
    "&.MuiPaginationItem-ellipsis": {
      // height: 25,
    },
    "&.MuiPaginationItem-previousNext": {
      borderRadius: "100%",
      marginLeft: 4,
      marginRight: 4,
      minWidth: "auto",
      width: 25,
    },
  };
});
