import React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import {
  Box,
  CircularProgress,
  FormHelperText,
  InputAdornment,
  InputBase,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import styled from "@emotion/styled";
// import { ArrowDownIcon } from "../../assets/icons";
import {
  errorColor,
  graphiteGrayColor,
  silverSageColor,
} from "../../assets/colors";
import { ChevronDownIcon } from "../../assets/icons";
// --------------------------------------------------------------------------------
export default function CustomDropDown({
  name,
  label,
  items,
  disabled,
  value,
  onChange,
  helperText,
  error,
  schema,
  loading,
  bgColor,
  hideHelperText,
  ...otherProps
}) {
  return (
    <FormControl variant="standard" fullWidth>
      <InputLabel
        shrink
        sx={{
          fontSize: 14,
          color: "#33383D",
          "&.Mui-focused": { color: "unset" },
          transform: "none",
          fontWeight: 500,
        }}
      >
        {label}
      </InputLabel>
      <StyledSelect
        name={name}
        label={label}
        value={value}
        onChange={onChange}
        disabled={disabled}
        notched
        startAdornment={
          loading && (
            <InputAdornment position="start">
              <CircularProgress size={20} />
            </InputAdornment>
          )
        }
        input={
          <BootstrapInput
            bgColor={bgColor}
            label={label}
            placeholder={otherProps.placeholder}
          />
        }
        IconComponent={(props) => (
          <ChevronDownIcon
            {...props}
            width={18}
            style={{ color: graphiteGrayColor }}
          />
        )}
        error={error}
        {...otherProps}
      >
        {items?.map((item) => (
          <MenuItem value={schema ? item[schema.value] : item}>
            {schema ? (
              <>
                {item.icon && (
                  <ListItemIcon sx={{ minWidth: "22px!important" }}>
                    {item.icon}
                  </ListItemIcon>
                )}
                <ListItemText sx={{ my: 0 }} primary={item[schema.label]} />
              </>
            ) : (
              item
            )}
          </MenuItem>
        ))}
      </StyledSelect>
      {!hideHelperText && (
        <FormHelperText error={error}>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
}

export const BootstrapInput = styled(InputBase)(
  ({ theme, startAdornment, endAdornment, bgColor, label }) => ({
    "label + &": {
      marginTop: label ? theme.spacing(3.8) : 0,
      transform: "none",
    },
    "&.MuiInputBase-root": {
      backgroundColor: bgColor,
      border: `1px solid ${silverSageColor}`,
      borderRadius: 8,
      transition: theme.transitions.create(["border-color"]),
      // padding: 0,
      paddingLeft: startAdornment && 8,
      "&.Mui-error": {
        borderColor: errorColor,
        boxShadow: "none",
      },
    },
    "& .MuiInputBase-input": {
      borderRadius: 8,
      position: "relative",
      fontSize: 12,
      padding: 8,
      paddingRight: "32px!important",
      // Use the system font instead of the default Roboto font.
      // fontFamily: [
      //   "-apple-system",
      //   "BlinkMacSystemFont",
      //   '"Segoe UI"',
      //   "Roboto",
      //   '"Helvetica Neue"',
      //   "Arial",
      //   "sans-serif",
      //   '"Apple Color Emoji"',
      //   '"Segoe UI Emoji"',
      //   '"Segoe UI Symbol"',
      // ].join(","),
    },
    "& + p": {
      transition: theme.transitions.create(["opacity"]),
      opacity: 0,
      "&.Mui-error": {
        opacity: 1,
      },
    },
    ".MuiSelect-icon": {
      right: 8,
    },
  })
);

export const StyledSelect = styled(Select)(({ theme }) => ({
  ".MuiSelect-select": { ":focus": { backgroundColor: "unset" } },
}));
