import {
  ADD_BANK_CARD_FAILURE,
  ADD_BANK_CARD_STARTED,
  ADD_BANK_CARD_SUCCESS,
  CHANGE_PROFILE_PICTURE_FAILURE,
  CHANGE_PROFILE_PICTURE_STARTED,
  CHANGE_PROFILE_PICTURE_SUCCESS,
  CREATE_USER_COMPANY_FAILURE,
  CREATE_USER_COMPANY_STARTED,
  CREATE_USER_COMPANY_SUCCESS,
  GET_BANK_CARDS_FAILURE,
  GET_BANK_CARDS_STARTED,
  GET_BANK_CARDS_SUCCESS,
  GET_PLANS_HISTORY_FAILURE,
  GET_PLANS_HISTORY_STARTED,
  GET_PLANS_HISTORY_SUCCESS,
  GET_USER_COMPANY_FAILURE,
  GET_USER_COMPANY_STARTED,
  GET_USER_COMPANY_SUCCESS,
  SEND_USER_EMAIL_FAILURE,
  SEND_USER_EMAIL_STARTED,
  SEND_USER_EMAIL_SUCCESS,
  UPGRADE_PLAN_FAILURE,
  UPGRADE_PLAN_STARTED,
  UPGRADE_PLAN_SUCCESS,
} from "../actionTypes";
// --------------------------------------------------------------------------------
const initialState = {
  rType: "",
  bankCards: [],
  plansHistory: [],
  userCompany: {},
  error: "",
};
const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_BANK_CARD_STARTED:
    case GET_BANK_CARDS_STARTED:
    case GET_PLANS_HISTORY_STARTED:
    case CHANGE_PROFILE_PICTURE_STARTED:
    case GET_USER_COMPANY_STARTED:
    case UPGRADE_PLAN_STARTED:
    case SEND_USER_EMAIL_STARTED:
    case CREATE_USER_COMPANY_STARTED:
      return {
        ...state,
        rType: action.type,
        error: "",
      };
    case ADD_BANK_CARD_SUCCESS:
    case CHANGE_PROFILE_PICTURE_SUCCESS:
    case UPGRADE_PLAN_SUCCESS:
    case SEND_USER_EMAIL_SUCCESS:
    case CREATE_USER_COMPANY_SUCCESS:
      return {
        ...state,
        rType: action.type,
        error: "",
      };
    case GET_BANK_CARDS_SUCCESS:
      return {
        ...state,
        rType: GET_BANK_CARDS_SUCCESS,
        bankCards: action.payload.bankCards,
        error: "",
      };
    case GET_PLANS_HISTORY_SUCCESS:
      return {
        ...state,
        rType: GET_PLANS_HISTORY_SUCCESS,
        plansHistory: action.payload.plansHistory,
        error: "",
      };
    case GET_USER_COMPANY_SUCCESS:
      return {
        ...state,
        rType: GET_USER_COMPANY_SUCCESS,
        userCompany: action.payload.userCompany,
        error: "",
      };
    case ADD_BANK_CARD_FAILURE:
    case GET_BANK_CARDS_FAILURE:
    case GET_PLANS_HISTORY_FAILURE:
    case CHANGE_PROFILE_PICTURE_FAILURE:
    case GET_USER_COMPANY_FAILURE:
    case UPGRADE_PLAN_FAILURE:
    case SEND_USER_EMAIL_FAILURE:
    case CREATE_USER_COMPANY_FAILURE:
      return {
        ...state,
        rType: action.type,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default userReducer;
