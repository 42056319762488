import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation, useParams } from "react-router-dom";

const useAppHooks = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();

  return { dispatch, t, navigate, params, location };
};

export default useAppHooks;
