import styled from "@emotion/styled";
import {
  FormControl,
  InputBase,
  InputLabel,
  InputAdornment,
  FormHelperText,
} from "@mui/material";
import {
  inputColor,
  graphiteGrayColor,
  errorColor,
  primaryColor,
  silverSageColor,
} from "../../assets/colors";
import { useEffect, useState } from "react";
import { getDirection } from "../../utils/helpers";
// import { RE_ENGLISH } from "../../constants";
// --------------------------------------------------------------------------------
const CustomInputBase = ({
  name,
  label,
  type,
  value,
  onChange,
  startAdornment,
  endAdornment,
  multiline,
  row,
  sx,
  error,
  helperText,
  pattern,
  maxLength,
  hideHelperText,
  ...otherProps
}) => {
  return (
    <FormControl variant="standard" fullWidth disabled={otherProps.disabled}>
      <InputLabel
        shrink
        sx={{
          fontSize: 14,
          color: graphiteGrayColor,
          "&.Mui-focused": {
            color: "unset",
          },
          "&.Mui-disabled": {
            opacity: 0.3,
          },
          transform: "none",
          fontWeight: 500,
          width: "100%",
        }}
      >
        {label}
      </InputLabel>
      <BootstrapInput
        name={name}
        type={type}
        value={value}
        onChange={onChange}
        startAdornment={
          startAdornment && (
            <InputAdornment sx={{ mr: 1 }}>{startAdornment}</InputAdornment>
          )
        }
        endAdornment={
          endAdornment && (
            <InputAdornment sx={{ ml: 1 }}>{endAdornment}</InputAdornment>
          )
        }
        multiline={multiline}
        rows={row}
        sx={sx}
        error={error}
        inputProps={{ pattern, maxLength }}
        direction={getDirection(value)}
        {...otherProps}
      />
      {!hideHelperText && (
        <FormHelperText error={error}>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
};

export default CustomInputBase;

export const BootstrapInput = styled(InputBase)(
  ({ theme, startAdornment, endAdornment, direction }) => ({
    "label + &": {
      [theme.breakpoints.up("xs")]: {
        marginTop: theme.spacing(3.5),
      },
      [theme.breakpoints.up("lg")]: {
        marginTop: theme.spacing(3.8),
      },
      transform: "none",
    },
    "&.MuiInputBase-root": {
      border: `1px solid ${silverSageColor}`,
      borderRadius: 8,
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      padding: 0,
      paddingLeft: startAdornment && 7,
      paddingRight: endAdornment && 7,
      "&.Mui-focused": {
        borderColor: primaryColor,
        boxShadow: "0px 0px 0px 2px rgba(65, 173, 206, 0.11)",
      },
      "&.Mui-error": {
        borderColor: errorColor,
        boxShadow: "none",
      },
    },
    "& .MuiInputBase-input": {
      direction,
      borderRadius: 8,
      position: "relative",
      fontSize: 14,
      padding: `10px ${endAdornment ? "0px" : "12px"} 10px ${
        startAdornment ? "0px" : "12px"
      }`,
      "&::placeholder": {
        fontSize: 12,
        textAlign: "left",
      },
      // Use the system font instead of the default Roboto font.
      // fontFamily: [
      //   "-apple-system",
      //   "BlinkMacSystemFont",
      //   '"Segoe UI"',
      //   "Roboto",
      //   '"Helvetica Neue"',
      //   "Arial",
      //   "sans-serif",
      //   '"Apple Color Emoji"',
      //   '"Segoe UI Emoji"',
      //   '"Segoe UI Symbol"',
      // ].join(","),
    },
  })
);
