import {
  CREATE_TICKET_FAILURE,
  CREATE_TICKET_STARTED,
  CREATE_TICKET_SUCCESS,
  GET_TICKETS_FAILURE,
  GET_TICKETS_STARTED,
  GET_TICKETS_SUCCESS,
  GET_TICKET_CATEGORIES_FAILURE,
  GET_TICKET_CATEGORIES_STARTED,
  GET_TICKET_CATEGORIES_SUCCESS,
} from "../actionTypes";
// --------------------------------------------------------------------------------
const initialState = {
  rType: "",
  tickets: { records: [], count: 1 },
  ticketCategories: [],
  error: "",
};
const ticketReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_TICKET_STARTED:
    case GET_TICKETS_STARTED:
    case GET_TICKET_CATEGORIES_STARTED:
      return {
        ...state,
        rType: action.type,
        error: "",
      };
    case CREATE_TICKET_SUCCESS:
      return {
        ...state,
        rType: CREATE_TICKET_SUCCESS,
        error: "",
      };
    case GET_TICKETS_SUCCESS:
      return {
        ...state,
        rType: GET_TICKETS_SUCCESS,
        tickets: {
          count: action.payload.tickets.count,
          records: action.payload.tickets.records,
        },
        error: "",
      };
    case GET_TICKET_CATEGORIES_SUCCESS:
      return {
        ...state,
        rType: GET_TICKET_CATEGORIES_SUCCESS,
        ticketCategories: action.payload.ticketCategories,
        error: "",
      };
    case CREATE_TICKET_FAILURE:
    case GET_TICKET_CATEGORIES_FAILURE:
    case GET_TICKETS_FAILURE:
      return {
        ...state,
        rType: action.type,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default ticketReducer;
